.production {
    width: 100%;
    height: 650px;
    padding-top: 80px;
    background-color: #BABABA;
    background-size: cover;

    .production_title {
        width: 100%;
        text-align: center;
    }
    .production_line_block{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .production_line {
            width: 200px;
            padding-top: 50px;
        }
    }

    .production_items{
        display: flex;
        justify-content: center;
        align-items: center;

        .production_item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 40px 0 0 0;
            width: 100%;
            height: 300px;
            
            .production_item_title {
                padding-bottom: 5px;
            }

            .production_item_products{
                padding: 5px;

                li{
                    padding-top: 15px;
                    color: black;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .production {
        width: 100%;
        height: 650px;
        padding-top: 80px;
        background-color: #BABABA;
        background-size: cover;
    
        .production_title {
            width: 100%;
            text-align: center;
        }
        .production_line_block{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .production_line {
                width: 200px;
                padding-top: 50px;
            }
        }
    
        .production_items{
            display: flex;
            justify-content: center;
            align-items: center;
    
            .production_item{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding: 40px 0 0 0;
                width: 100%;
                height: 300px;
                font-size: 10px;
                
                .production_item_title {
                    padding-bottom: 5px;
                    font-size: 12px;
                }
    
                .production_item_products{
                    padding: 5px;
    
                    li{
                        padding-top: 15px;
                        color: black;
                    }
                }
            }
        }
    }
}