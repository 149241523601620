.header {
    position: relative;
    background-image: url(/Assets/background/background.jpg);
    background-size: cover;
    padding-top: 25px;
    padding-bottom: 650px;

    .header_nav{
        display: flex;
        justify-content: space-between;
        padding: 75px 100px 0 100px;
        color: whitesmoke;
        font-size: 12px;  
        align-items: center;     

        .header_title{
            padding-bottom: 10px;
            text-transform: uppercase;
        }
        .header_nav_email_order{
            display: inherit;
            align-items: center;
            

            .header_email{
                padding-right: 15px;
                padding-bottom: 10px;
            }
            .header_order{
                margin-bottom: 10px;
                font-size: 20px;
                padding: 10px;
                text-decoration: none;
                background: none;
                color: white;
                border: 2px solid #4caf50;
                border-radius: 50px;
                -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;      
                cursor: pointer;          
            }
            .header_order:hover{
                background-color: #4caf50;
            }
        }
}

    .header_description{
        display: flex;
        position: absolute;
        left: 45%;
        top: 40%;
        right: 45%;
        font-size: 48px;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;

        .header_description_title{
            padding-top: 50px;
        }
    }

    .header_line_block{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .header_line{
            position: absolute;
            justify-content: center;
            align-items: center;
            top: 71%;
            width: 300px;
        }
    }
    

    .header_description_second {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 72%;
        font-size: 48px;
        color: white;
    }
}

@media only screen and (max-width: 576px) {
    .header {
        position: relative;
        background-image: url(/Assets/background/background.jpg);
        background-size: cover;
        padding-top: 25px;
        padding-bottom: 450px;
        width: 100%;
    
        .header_nav{
            display: flex;
            justify-content: space-between;
            padding: 35px 20px 0 20px;
            color: whitesmoke;
            align-items: center;     
    
            .header_title{
                padding-bottom: 10px;
                font-size: 8px;  
                text-transform: uppercase;
            }
            .header_nav_email_order{
                display: inherit;
                align-items: center;
                font-size: 12px;
                
                .header_email{
                    padding-right: 15px;
                    font-size: 8px;  
                    padding-bottom: 10px;
                }

                .header_order{
                    margin-bottom: 10px;                   
                    text-decoration: none;
                    background: none;
                    color: white;
                    border: 2px solid #4caf50;
                    border-radius: 50px;
                    -webkit-transition-duration: 0.4s;
                    transition-duration: 0.4s;      
                    cursor: pointer;          
                }
                .header_order:hover{
                    background-color: #4caf50;
                }
            }
    }
    
        .header_description{
            display: flex;
            position: absolute;
            left: 45%;
            top: 40%;
            right: 45%;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: white;
    
            .header_description_title{
                padding-top: 50px;
                font-size: 38px;
            }
        }
    
        .header_line_block{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-top: 200px;
    
            .header_line{
                position: absolute;
                justify-content: center;
                align-items: center;
                top: 71%;
                width: 300px;
            }
        }
        
    
        .header_description_second {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 72%;
            font-size: 48px;
            color: white;
        }
    }
}

@media only screen and (max-width: 768px) {
    .header {
        position: relative;
        background-image: url(/Assets/background/background.jpg);
        background-size: cover;
        padding-top: 25px;
        padding-bottom: 450px;
    
        .header_nav{
            display: flex;
            justify-content: space-between;
            padding: 35px 20px 0 20px;
            color: whitesmoke;
            font-size: 12px;  
            align-items: center;     
    
            .header_title{
                padding-bottom: 10px;
                text-transform: uppercase;
            }
            .header_nav_email_order{
                display: inherit;
                align-items: center;
                
    
                .header_email{
                    padding-right: 15px;
                    padding-bottom: 10px;
                }
                .header_order{
                    margin-bottom: 10px;
                    font-size: 20px;
                    padding: 10px;
                    text-decoration: none;
                    background: none;
                    color: white;
                    border: 2px solid #4caf50;
                    border-radius: 50px;
                    -webkit-transition-duration: 0.4s;
                    transition-duration: 0.4s;      
                    cursor: pointer;          
                }
                .header_order:hover{
                    background-color: #4caf50;
                }
            }
    }
    
        .header_description{
            display: flex;
            position: absolute;
            left: 45%;
            top: 40%;
            right: 45%;
            font-size: 48px;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: white;
    
            .header_description_title{
                padding-top: 50px;
            }
        }
    
        .header_line_block{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-top: 200px;
    
            .header_line{
                position: absolute;
                justify-content: center;
                align-items: center;
                top: 71%;
                width: 300px;
            }
        }
        
    
        .header_description_second {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 72%;
            font-size: 48px;
            color: white;
        }
    }
}

