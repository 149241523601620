.feedback{
    background-color: #BABABA;
    background-size: cover;
    width: 100%;
    height: 700px;
    
    .feedback_title {
        padding-top: 100px;
        width: 100%;
        text-align: center;
        color: #322;
    }

    .feedback_line_block{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .feedback_line {
            width: 200px;
            padding-top: 50px;
        }
    }

    .feedback_info,
    .feedback_form{
        flex: 1;
    }
    .feedback_block{
        display: flex;
        justify-content: space-evenly;
        padding-top: 100px;

        .feedback_items {
            .item{
                padding-top: 45px;
            }
        }
        .icon{
            height: 60px;
            width: 60px;
            line-height: 60px;
            background-color: #4caf50;
            text-align: center;
            color: #fff;
            border-radius: 50%;
            margin-right: 16px;
        }
    }
    .feedback_form{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 750px;

        .feedback_inputs{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;

            .nameError{
                display: flex;
                align-items: center;
                height: 65px;
            }

            .emailError{
                display: flex;
                align-items: center;
                height: 65px;
            }

            .textb{
                display: flex;
                justify-content: space-between;
                width: 45%;
                height: 50px;
                padding: 10px;
                background-color: #222;
                color: #fff;
                margin-bottom: 20px;
            }
    }
        textarea {
            width: 100%;
            min-height: 200px;
            max-height: 400px;
            resize: vertical;
            padding: 12px;
            background-color: #222;
            border: none;
            color: #fff;
            margin-bottom: 20px;
        }

        .btn {
            width: 120px;
            height: 40px;
            margin-left: auto;
            background-color: #4caf50;
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            border: 2px solid #4caf50;
            transition: .3s linear;
            
            .bth:hover{
                background-color: transparent;
                color: #3494db;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .feedback{
        background-color: #BABABA;
        background-size: cover;
        max-width: 750px;
        height: 1050px;
        
        .feedback_title {
            padding-top: 100px;
            width: 100%;
            text-align: center;
            color: #322;
        }
    
        .feedback_line_block{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
    
            .feedback_line {
                width: 200px;
                padding-top: 50px;
            }
        }
    
        .feedback_info,
        .feedback_form{
            flex: 1;
        }
        .feedback_block{
            display: flex;
            flex-direction: column;
            padding-top: 100px;
    
            .feedback_items {
                display: flex;
                flex-direction: column;

                .feedback_item{
                    font-size: 10px;
                    
                }

                .item{
                    padding-top: 45px;
                    font-size: 10px;
                    align-items: center;
                }
            }
            .icon{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 40px;
                line-height: 60px;
                background-color: #4caf50;
                color: #fff;
                border-radius: 50%;
                margin-right: 16px;
                
            }
        }
        .feedback_form{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 30px;
            max-width: 750px;
    
            .feedback_inputs{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
    
                .nameError{
                    display: flex;
                    align-items: center;
                    height: 65px;
                }
    
                .emailError{
                    display: flex;
                    align-items: center;
                    height: 65px;
                }
    
                .textb{
                    display: flex;
                    justify-content: space-between;
                    width: 45%;
                    height: 50px;
                    padding: 10px;
                    background-color: #222;
                    color: #fff;
                    margin-bottom: 20px;
                }
        }
            textarea {
                width: 100%;
                min-height: 200px;
                max-height: 400px;
                resize: vertical;
                padding: 12px;
                background-color: #222;
                border: none;
                color: #fff;
                margin-bottom: 20px;
            }
    
            .btn {
                width: 120px;
                height: 40px;
                margin-left: auto;
                background-color: #4caf50;
                color: #fff;
                text-transform: uppercase;
                cursor: pointer;
                border: 2px solid #4caf50;
                transition: .3s linear;
                
                .bth:hover{
                    background-color: transparent;
                    color: #3494db;
                }
            }
        }
    }
}