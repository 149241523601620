.services{
    background-color: #fff;
    width: 100%;
    height: 350px;
    padding-top: 20px;
    background-color: #BABABA;
    background-size: cover;

    .services_title {
        width: 100%;
        text-align: center;
    }
    .services_line_block{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .services_line {
            width: 200px;
            padding-top: 50px;
        }
    }

    .services_items{
        position: relative;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-right: 90px;
        height: 300px;

        .services_item{
            padding-bottom: 50px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .services{
        background-color: #fff;
        width: 100%;
        height: 350px;
        padding-top: 20px;
        background-color: #BABABA;
        background-size: cover;
    
        .services_title {
            width: 100%;
            text-align: center;
        }
        .services_line_block{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .services_line {
                width: 200px;
                padding-top: 50px;
            }
        }
    
        .services_items{
            position: relative;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 300px;
            padding: 0;
    
            .services_item{
                font-size: 10px;
            }
        }
    }
}
