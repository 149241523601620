@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

* {
    margin: 0;
    list-style: none;
    padding: 0;
    outline: 0;
    font-family: 'Roboto', sans-serif;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, system-ui, sans-serif, 'Roboto' sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    max-width: 100vwax;
}

hr {
    border: none;
    color: #4caf50;
    background-color: #4caf50;
    height: 2px;
}

@media only screen and (max-width: 768px) {
    body {
        overflow-x: hidden;
        max-width: 100vw;
        max-width: 750px;
    }
}