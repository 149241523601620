.contacts{
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
    height: 920px;

    .contacts_title{
        width: 100%;
        text-align: center;
    }
    .contacts_line_block{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .contacts_line{
            width: 200px;
            padding-top: 50px;
        }
    }

    .contacts_map{
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 80px;
    }
       
    .contacts_items{
        display: flex;
        justify-content: space-evenly;   

        .contacts_item{
            display: flex;
            padding: 30px 0 30px 0;
            width: 200px;
            
            table{
                position: relative;
                
                h4{
                    float: left;
                }
                span {
                    float: left;
                }
            }
        }   
    }
}

@media only screen and (max-width: 768px) {
    .contacts{
        padding-top: 80px;
        padding-bottom: 80px;
        width: 100%;
        height: 920px;
    
        .contacts_title{
            width: 100%;
            text-align: center;
        }
        .contacts_line_block{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
    
            .contacts_line{
                width: 200px;
                padding-top: 50px;
            }
        }
    
        .contacts_map{
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: 80px;
        }
           
        .contacts_items{
            display: flex;
            justify-content: space-around;   
    
            .contacts_item{
                display: flex;
                padding: 30px 0 30px 0;
                width: 200px;
                
                table{
                    position: relative;
                    
                    h4{
                        float: left;
                        font-size: 10px;
                    }
                    span {
                        float: left;
                        font-size: 8px;
                    }
                }
            }
        }
    }
}