.advantage{
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
    height: 400px;

    .advantage_title{
        width: 100%;
        text-align: center;
    }
    .advantage_line_block{
        display: flex;
        justify-content: center;
        align-items: center;
        
        .advantage_line{
            width: 200px;
            padding-top: 50px;
        }
    }
    .advantage_items{
        position: relative;
        display: flex;
        justify-content: space-around;
        padding: 50px 0 0 0;
        width: 100%;
        height: 300px;

        .advantage_item{
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 350px;
            width: 200px;
            text-align: center;
            font-size: 20px;
            
            .advantage_item_name{
                padding-top: 20px;
            }
            .advantage_item_img{
                width: 170px;
                height: 170px;
            }
        }
    }

}

@media screen and (max-width: 576px) {
    .advantage{
        padding-top: 80px;
        width: 100%;
        height: 400px;
    
        .advantage_title{
            width: 100%;
            text-align: center;
            font-size: 24px;
        }
    
        .advantage_line_block{
            display: flex;
            justify-content: center;
            align-items: center;
            
            .advantage_line{
                width: 200px;
                padding-top: 50px;
            }
        }
    
    
        .advantage_items{
            position: relative;
            display: flex;
            justify-content: space-around;
            padding: 50px 0 0 0;
            width: 100%;
            height: 300px;
    
            .advantage_item{
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 350px;
                width: 200px;
                text-align: center;
                font-size: 10px;
                
                .advantage_item_name{
                    padding-top: 20px;
                    font-size: 10px;
                }
                .advantage_item_img{
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .advantage{
        padding-top: 80px;
        width: 100%;
        height: 400px;
    
        .advantage_title{
            width: 100%;
            text-align: center;
        }
    
        .advantage_line_block{
            display: flex;
            justify-content: center;
            align-items: center;
            
            .advantage_line{
                width: 200px;
                padding-top: 50px;
            }
        }
    
    
        .advantage_items{
            position: relative;
            display: flex;
            justify-content: space-around;
            padding: 50px 0 0 0;
            width: 100%;
            height: 300px;
    
            .advantage_item{
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 350px;
                width: 200px;
                text-align: center;
                font-size: 10px;
                
                .advantage_item_name{
                    padding-top: 20px;
                    font-size: 8px;
                }
                .advantage_item_img{
                    width: 50px;
                    height: 50px;
                }
            }
        }
    
    }
}
